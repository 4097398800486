<template>
  <div class="container">
    <div class="row justify-content-center">
      <div
        class="
          col-12 col-md-7 col-lg-5 col-xl-4
          my-5
          card
          light-shadow
          p-5
          login-box-container
        "
      >
        <h1 class="display-4 text-center mb-3">Sign in</h1>
        <p class="text-muted text-center mb-5">Access to our portal.</p>
        <form @submit="login" validate>
          <input-control
            v-model="username"
            autofocus
            control="email"
            :required="true"
            >Email Address</input-control
          >
          <input-control v-model="password" control="password" :required="true"
            >Password</input-control
          >
          <p class="mb-3 mt-0 float-right">
            <router-link to="/reset-password"
              >Forgot Login Password?</router-link
            >
          </p>
          <save
            :saving="auth.authenticating"
            classes="btn btn-lg btn-block btn-black mb-3"
            >Sign in</save
          >
        </form>
        <div class="row text-center small">
          <div class="col">
            <router-link to="/privacy-policy">Privacy Policy</router-link>
          </div>
          <div class="col">
            <router-link to="/terms">Terms &amp; Conditions</router-link>
          </div>
        </div>
      </div>
      <secondStepVerification />
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import secondStepVerification from "./second-step-verification.vue";

export default {
  name: "LoginForm",
  components: {
    secondStepVerification,
  },
  computed: {
    ...mapState(["auth"]),
  },
  data() {
    return {
      username: null,
      password: null,
    };
  },
  methods: {
    ...mapActions({
      doLogin: "auth/login",
      getUserDetails: "auth/getUser",
      // getNotifications: "users/getNotifications",
      getProfile: "settings/getCompanyProfile",
    }),
    login: function (e) {
      e.preventDefault();
      let vm = this;
      this.doLogin({
        username: this.username,
        password: this.password,
      })
        .then(function (isLoggedIn) {
          if (vm.auth.secondStepVerificationEnabled == true) {
            vm.$modal.show("second-step-verification");
          } else {
            if (isLoggedIn) {
              vm.$router.push({ name: "dashboard" }, function () {
                vm.getUserDetails().then(() => {
                  vm.getProfile();
                  vm.getNotifications({ status: "unread", limit: 20 });
                });
              });
            }
          }
        })
        .catch(function (err) {
          if (!err.accessDenied) {
            Swal.fire({
              title: "Login Failed",
              html: `${
                (err.data || {}).message || err.message
              }, please contact us at <a href='mailto:support@communitycareconnection.com'>support@communitycareconnection.com</a> to fix this.`,
              icon: "error",
            });
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 767px) {
  .login-box-container {
    max-width: 90%;
  }
}
</style>
